var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"analysis container"},[_c('h4',{staticClass:"title"},[_vm._v(" Score Breakdown "),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPer),expression:"showPer"}],attrs:{"size":"small","type":"success"},on:{"click":function($event){_vm.showPer = false}}},[_vm._v(" Show details ")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPer),expression:"!showPer"}],attrs:{"size":"small","type":"danger"},on:{"click":function($event){_vm.showPer = true}}},[_vm._v(" Hide details ")])],1),_vm._l((_vm.analysis.answers),function(passage,title,index){return _c('div',{key:index},[_c('hr'),_c('h4',[_vm._v(_vm._s(_vm.sectionArr[title])+":")]),_vm._l((Math.ceil(passage.length / _vm.lineCount)),function(i){return _c('div',{key:i,staticClass:"answer-table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(0,true),_c('tbody',_vm._l((passage),function(question,questionIndex){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(
              (i - 1) * _vm.lineCount <= questionIndex &&
                questionIndex < i * _vm.lineCount
            ),expression:"\n              (i - 1) * lineCount <= questionIndex &&\n                questionIndex < i * lineCount\n            "}],key:questionIndex},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(questionIndex + 1))]),_c('td',[(question)?_c('div',{class:_vm.showAnswerCorrectPer(question.correct, question.total)},[_c('el-tooltip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPer),expression:"showPer"}],attrs:{"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(
                      _vm.showWrongAns(question.answers, question.student_answers)
                    )},slot:"content"}),_c('b',[_vm._v(_vm._s(_vm.getPer(question.correct / question.total))+"% ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showPer),expression:"!showPer"}]},[_c('el-tooltip',{attrs:{"content":_vm.showName(true, question),"placement":"top"}},[_c('b',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(question.correct)+" ")])]),_vm._v(" / "),_c('b',[_vm._v(" "+_vm._s(question.omit)+" ")]),_vm._v(" / "),_c('el-tooltip',{attrs:{"content":_vm.showName(false, question),"placement":"top"}},[_c('b',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(question.wrong)+" ")])])],1)],1):_vm._e()]),_c('td',[(question)?_c('div',[_c('a',{staticStyle:{"display":"inline-block","width":"100%","height":"100%"},attrs:{"target":"_blank","href":`https://sat.ivy-way.com/class/explanation/question/${
                      question.question_id
                    }?user_exam_id=${_vm.user_exam_id}&practiceType=${_vm.exam_type}&order=${questionIndex +
                      1}&passageIndex=${index + 1}&token=${_vm.token}`}},[(question.answers && question.answers.length > 0)?_c('b',{staticClass:"text-success"},_vm._l((question.answers),function(answer,index){return _c('span',{key:index},[(answer[0])?_c('span',[_vm._v(" "+_vm._s(answer[0])+" ")]):_vm._e()])}),0):_c('b',{staticClass:"text-success"},[_vm._v("-")])])]):_vm._e()])])}),0)])])})],2)})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("#")]),_c('th',{staticClass:"green-td"},[_vm._v("Students")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")])])])
}]

export { render, staticRenderFns }