<template>
  <div class="analysis container">
    <h2>{{ getIvywayExamTitle(analysis.exam) }} - Analysis</h2>
    <hr />
    <div class="row sat">
      <div class="col-md-5 sat_left print_div">
        <div v-if="testType === 'SAT'">
          <p>SAT</p>
          <span><i class="fa fa-registered" aria-hidden="true"></i></span>
        </div>
      </div>
      <div class="col-md-7 sat_right row">
        <div class="col-md-8" v-if="analysis.teacher !== undefined">
          <ul>
            <li>
              Class:
              <a :href="`/session-classes/${classId}?section=details`"
                ><span>{{ analysis.title }}</span>
              </a>
            </li>
            <li>
              Teacher:
              <span>
                {{
                  user.displayName(
                    analysis.teacher.first_name,
                    analysis.teacher.last_name
                  )
                }}
              </span>
            </li>
            <li>
              Test: <span>{{ getIvywayExamTitle(analysis.exam) }}</span>
            </li>
          </ul>
        </div>
        <div class="col-md-4 img" style="line-height:93px;">
          <img
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/logo/logo.png"
          />
        </div>
      </div>
    </div>
    <!-- SAT 分析 -->
    <div v-if="testType === 'SAT'">
      <SATAnalysis
        :tests="tests"
        :analysis="analysis"
        :avgScore="tests[whichWeek]"
      />
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import echarts from "echarts";
import courseApi from "@/apis/course";
import SATAnalysis from "@/views/sessionClasses/analysis/components/OnlineSAT";
import { user } from "@ivy-way/material";

export default {
  metaInfo() {
    return {
      title: "Analysis - Ivy-Way Academy"
    };
  },

  components: {
    SATAnalysis
  },

  mixins: [],

  props: [],
  data() {
    return {
      testType: "SAT",
      activeReport: "reportSAT",
      showScores: false,
      showPer: true,
      progress: {
        progress_total_score: 0,
        progress_section_english_score: 0,
        progress_section_math_score: 0,
        progress_reading_score: 0,
        progress_math_score: 0,
        progress_aih_score: 0,
        progress_ais_score: 0,
        progress_coe_score: 0,
        progress_wic_score: 0,
        progress_eoi_score: 0,
        progress_hoa_score: 0,
        progress_psada_score: 0,
        progress_ptam_score: 0,
        progress_sec_score: 0,
        progress_essay_reading: 0,
        progress_essay_analysis: 0,
        progress_essay_writing: 0
      },
      progressType: 1,
      showAnswer: true,
      tests: [],
      analysis: {}
    };
  },
  computed: {
    user() {
      return user;
    },
    classId() {
      let classId = 0;
      if (this.$route.query.class_id) {
        classId = this.$route.query.class_id;
      }
      return classId;
    },
    whichWeek() {
      let week = 0;
      if (this.$route.query.week) {
        week = this.$route.query.week;
      }
      return week;
    }
  },
  watch: {},

  mounted() {
    this.$nextTick(function() {
      this.draw("reportSAT");
    });
    this.getClassExams();
    this.getClassAnalysis();
  },

  methods: {
    handleClick(val) {
      console.log(this.activeReport);
      this.draw(this.activeReport);
    },
    async getClassAnalysis() {
      this.analysis = await courseApi.getClassAnalysis(this.classId, {
        which_week: this.whichWeek,
        test_type: this.$route.query.test_type,
        session_class_assign_test_id: this.$route.query.session_class_assign_test_id
      });
    },
    async getClassExams() {
      this.tests = await courseApi.getClassExams(this.classId);
    },
    draw(id) {
      this.charts = echarts.init(document.getElementById(id));
      let vm = this;
      if (id === "reportSAT") {
        this.charts.setOption({
          title: {
            text: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: [
              "Justin Hsieh",
              "Tiger Hsu",
              "Seamus Chang",
              "Tim Shih",
              "Sidney Liu"
            ]
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
              "Test 1",
              "Test 2",
              "Test 3",
              "Test 4",
              "Test 5",
              "Test 6",
              "Test 7"
            ]
          },
          yAxis: {
            type: "value",
            max: 1600,
            min: 600,
            maxInterval: 100,
            minInterval: 1
          },
          series: [
            {
              name: "Justin Hsieh",
              type: "line",
              data: [1120, 1132, 1101, 1134, 1190, 1230, 1210]
            },
            {
              name: "Tiger Hsu",
              type: "line",
              data: [1220, 1182, 1191, 1234, 1290, 1330, 1310]
            },
            {
              name: "Seamus Chang",
              type: "line",
              data: [1150, 1232, 1201, 1154, 1190, 1430, 1410]
            },
            {
              name: "Tim Shih",
              type: "line",
              data: [1320, 1332, 1301, 1334, 1390, 1380, 1320]
            },
            {
              name: "Sidney Liu",
              type: "line",
              data: [1020, 1032, 1090, 1034, 1290, 1330, 1320]
            }
          ]
        });
      }
      if (id === "reportACT") {
        this.charts.setOption({
          title: {
            text: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["Justin Hsieh", "Tiger Hsu", "Seamus Chang"]
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
              "Test 1",
              "Test 2",
              "Test 3",
              "Test 4",
              "Test 5",
              "Test 6",
              "Test 7"
            ]
          },
          yAxis: {
            type: "value",
            max: 36,
            min: 0,
            maxInterval: 6,
            minInterval: 1
          },
          series: [
            {
              name: "Justin Hsieh",
              type: "line",
              data: [20, 30, 33, 25, 28, 34, 36]
            },
            {
              name: "Tiger Hsu",
              type: "line",
              data: [30, 20, 25, 33, 15, 25, 32]
            },
            {
              name: "Seamus Chang",
              type: "line",
              data: [30, 15, 20, 18, 33, 34, 29]
            }
          ]
        });
      }
      if (id === "reportTOEFL") {
        this.charts.setOption({
          title: {
            text: ""
          },
          tooltip: {
            trigger: "axis"
          },
          legend: {
            data: ["Justin Hsieh", "Tiger Hsu"]
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: [
              "Test 1",
              "Test 2",
              "Test 3",
              "Test 4",
              "Test 5",
              "Test 6",
              "Test 7"
            ]
          },
          yAxis: {
            type: "value",
            max: 120,
            min: 0,
            maxInterval: 10,
            minInterval: 1
          },
          series: [
            {
              name: "Justin Hsieh",
              type: "line",
              data: [70, 95, 100, 105, 110, 115, 120]
            },
            {
              name: "Tiger Hsu",
              type: "line",
              data: [60, 95, 98, 101, 104, 105, 115]
            }
          ]
        });
      }
    }
  }
};
</script>

<style scoped>
hr {
  border-top: 2px solid #42a16a;
}
.analysis {
  padding: 20px;
}
.title {
  font-size: 30px;
}
.total p,
.total ul {
  margin: 0px;
}

.total {
  margin-top: 30px;
}
.total .title {
  font-size: 30px;
  color: #43a06b;
}
.total_under {
  padding: 0px;
}
.total_above .score {
  font-size: 60px;
  color: #222;
  font-weight: 700;
  line-height: 70px;
  height: 70px;
}
.total .score + p {
  margin-top: 25px;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  border-left: 4px solid #ccc;
}
.sat_left > div > span {
  display: inline-block;
  position: absolute;
  left: 290px;
  top: 28px;
}
.sat_left > div > p:first-child {
  font-size: 140px;
  font-weight: 700;
  color: #43a06b;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  border-bottom: 1px solid#DCDFE6;
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat_right img {
  width: 90%;
}
.green-td {
  width: 96px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
  background-color: #43a06b;
}
.table td,
.table th {
  height: 20px;
  padding: 2px 5px;
  text-align: center;
  vertical-align: middle;
  border: #4fb81e 1px solid;
}
.demo-block-control {
  /* border-top: 1px solid #eaeefb; */
  height: 44px;
  line-height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #4fb81e;
  cursor: pointer;
  position: relative;
}
.demo-block-control:hover {
  /* color: #409eff; */
  background-color: #f9fafc;
}
</style>
